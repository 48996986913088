// @flow

import React, { useState, useEffect } from 'react'
import type { Node } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSelected } from '../../../hooks'

import { getTypes } from '../../../core/api/api.request'
import { getLabels } from '../../../core/api/api.label'
import { getAdminAndManagerOptionList } from '../../../core/api/api.profile'
import { getContractors } from '../../../core/api/api.contractor'

import NewSelectSimple from '../../../components/NewSelectSimple'
import NewSelectAsync from '../../../components/NewSelectAsync'
import DueDateEdit from '../../../components/Request/RequestInfo/DueDate/DueDateEdit'
import Button from '../../../components/Button'

import styles from './RequestMassEditModal.module.scss'

import { STATUSES_COLORS } from '../../../ui/MainColors.constants'

type Props = {
  field: Object,
  fields: Array<Object>,
  onChange: (string, string, any) => void,
  onChangeField: (string, string) => void,
  onRemove: string => void,
}

const RequestField = (props: Props): Node => {
  const { field, fields, payload } = props
  const { t } = useTranslation('Request')

  const [valueOption, setValueOption] = useState(null)
  const [selectedOptions, changeSelectedOptions, setSelectedOptions] =
    useSelected([])

  const [selectedCount, setSelectedCount] = useState(null)

  useEffect(() => {
    props.onChange(
      field.name,
      'value',
      selectedOptions.map(l => l.id)
    )
  }, [selectedOptions])

  useEffect(() => {
    if (field.command === 'clear') {
      props.onChange(field.name, 'value', undefined)
    }
  }, [field.command])

  useEffect(() => {
    props.onChange(field.name, 'value', '')
    setSelectedOptions([])
    setValueOption(null)
  }, [field.name])

  const requestStatuses = useSelector(state => state.init.request_status)

  const statusOptions = requestStatuses.map(status => ({
    value: status.id,
    label: t(status.title),
    style: { color: STATUSES_COLORS[status.css] },
  }))

  const getFieldsOptions = () =>
    fields
      .filter(
        f =>
          Object.keys(payload).indexOf(f.name) === -1 || f.name === field.name
      )
      .map(f => ({
        value: f.name,
        label: t(`Field_${f.name}`),
      }))

  const getCommandOptions = () => {
    const fieldObj = fields.find(f => f.name === field.name)

    if (fieldObj) {
      return fieldObj.commands.map(command => ({
        value: command,
        label: t(`Command_${command}`),
      }))
    }

    return []
  }

  const renderField = () => {
    const { name, command, value } = field

    if (command === 'clear') {
      return null
    }

    let fieldComponent = null

    switch (name) {
      case 'status':
        fieldComponent = (
          <NewSelectSimple
            className={styles.selectValue}
            options={statusOptions}
            value={valueOption}
            selectedItems={valueOption ? [valueOption] : []}
            onChange={status => {
              props.onChange(name, 'value', status.value)
              setValueOption(status)
            }}
          />
        )
        break
      case 'due_date':
        fieldComponent = (
          <DueDateEdit
            dateTime={value}
            style={{ maxWidth: 'initial' }}
            clearStyle={{ marginLeft: '10px' }}
            onChange={datetime => props.onChange(name, 'value', datetime)}
          />
        )
        break
      case 'category':
        fieldComponent = (
          <NewSelectAsync
            pageSize={10}
            className={styles.selectValue}
            api={getTypes}
            searchKey='name'
            placeholder={t('Value')}
            getLabel={c => c.name}
            getSelectedLabel={c => c.name}
            view='dropdown'
            selectedItems={valueOption ? [valueOption] : []}
            onClick={option => {
              props.onChange('value', option.id)
              setValueOption(option)
            }}
          />
        )
        break
      case 'assignees':
        fieldComponent = (
          <NewSelectAsync
            isMulti
            noAll
            view='dropdown'
            pageSize={10}
            api={getAdminAndManagerOptionList}
            placeholder={t('Value')}
            getLabel={l => l.fullname}
            getSelectedLabel={l => l.fullname}
            selectedItems={selectedOptions}
            setSelectedItems={setSelectedOptions}
            isAllSelected={selected => selectedCount === selected.length}
            setMetaCount={setSelectedCount}
            searchKey='search'
            className={styles.selectValue}
            minSearchLength={1}
            onClick={item => changeSelectedOptions(item)}
          />
        )
        break

      case 'labels':
        fieldComponent = (
          <NewSelectAsync
            isMulti
            noAll
            view='dropdown'
            pageSize={10}
            api={getLabels}
            placeholder={t('Value')}
            getLabel={l => l.name}
            getSelectedLabel={l => l.name}
            selectedItems={selectedOptions}
            setSelectedItems={setSelectedOptions}
            isAllSelected={selected => selectedCount === selected.length}
            setMetaCount={setSelectedCount}
            searchKey='search'
            className={styles.selectValue}
            minSearchLength={1}
            onClick={item => changeSelectedOptions(item)}
          />
        )
        break

      case 'contractors':
        fieldComponent = (
          <NewSelectAsync
            isMulti
            noAll
            view='dropdown'
            pageSize={10}
            api={getContractors}
            placeholder={t('Value')}
            getLabel={l => l.name}
            getSelectedLabel={l => l.name}
            selectedItems={selectedOptions}
            setSelectedItems={setSelectedOptions}
            isAllSelected={selected => selectedCount === selected.length}
            setMetaCount={setSelectedCount}
            searchKey='search'
            className={styles.selectValue}
            minSearchLength={1}
            onClick={item => changeSelectedOptions(item)}
          />
        )
        break
    }

    return fieldComponent
  }

  return (
    <div className={styles.requestField}>
      <div>{t('Field')}</div>
      <div className={styles.fieldSelects}>
        <NewSelectSimple
          useSearch
          className={styles.selectField}
          placeholder={t('SelectField')}
          options={getFieldsOptions()}
          value={getFieldsOptions().find(f => f.value === field.name)}
          onChange={option => {
            props.onChangeField(field.name, option.value)
          }}
        />
        <NewSelectSimple
          className={styles.selectCommand}
          placeholder={t('SelectCommand')}
          options={getCommandOptions()}
          value={getCommandOptions().find(o => o.value === field.command)}
          onChange={option =>
            props.onChange(field.name, 'command', option.value)
          }
        />
        <Button.Delete onClick={() => props.onRemove(field.name)} />
      </div>
      {field.name && field.command && renderField()}
    </div>
  )
}

export default RequestField
