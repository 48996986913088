// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Stickyfill from 'stickyfilljs'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import WidgetAddEntities from '../../components/widgets/WidgetAddEntities'
import WidgetParticipantsList from '../../components/widgets/WidgetParticipantsList/WidgetParticipantsList'
import AddressWidget from '../../components/widgets/AddressWidget'
import BuildingGroupWidget from '../../components/widgets/BuildingGroupWidget/BuidingGroupWidget'
import DueDatePopup from '../../components/Request/RequestInfo/DueDate/Popup'
import LabelsPopup from '../../components/Labels/LabelsPopup'
import ConfirmationPopup from '../../components/modals/ConfirmationPopup'
import ProvidersWidgetContainer from '../../components/widgets/ProvidersWidget'
import * as actions from '../../containers/RequestView/RequestView.actions'
import {
  isMenuCollapsed,
  getUser,
  isWizardEnabled,
} from '../../utils/commonSelectors'
import { setWidget } from '../Widget/Widget.actions'
import Widgets, { WIDGET_TYPES } from './widgets'
import { USER_GROUPS } from '../../constants'
import { postDataLoad } from '../../components/Post/Post.actions'
import { requestDataLoad } from '../../components/RequestCreate/RequestCreate.action'
import { getProfile } from '../../core/api/api.profile'
import { getBuilding } from '../../core/api/api.building'
import { getFlat } from '../../core/api/api.flat'
import { updateRequest } from '../../core/api/api.massAction'
import NewWizard from '../../components/NewWizard'
import { getActiveWidget } from '../Widget/Widget.selectors'

import {
  TO_NOT_SIGNEDUP_DWELLERS,
  TO_NOT_SIGNEDUP_LANDLORDS,
  TO_SIGNEDUP_DWELLERS,
  TO_SIGNEDUP_LANDLORDS,
} from '../../components/Post/Post.constants'

class RequestWidgets extends Component {
  state = { wizardData: null }

  componentDidMount() {
    const elements = document.querySelectorAll('.widget__container--content')
    Stickyfill.add(elements)
  }

  componentDidUpdate(prevProps) {
    const { postLoaded, requestLoaded } = this.props

    if (postLoaded && !prevProps.postLoaded) {
      this.props.push('/post/create')

      return
    }

    if (requestLoaded && !prevProps.requestLoaded) {
      this.props.push('/request/create')

      return
    }
  }

  componentWillUnmount() {
    this.props.setWidget(null)
  }

  updateFiles = (uploaded: Array<Object>) => {
    const {
      request: { id, file_objs: fileObjs },
      match: { params },
    } = this.props

    const outbound = params?.outbound
    this.props.updateFiles(id, outbound, fileObjs.concat(uploaded))
  }

  createFeedback = () => {
    const {
      request: { id },
    } = this.props

    this.props.push(`/request/${id}/feedback/create`)
  }

  createPost = async () => {
    const {
      request: { id, title, text, building },
    } = this.props

    let buildingObj = null

    if (building) {
      try {
        buildingObj = await getBuilding(building)
      } catch {
        buildingObj = null
      }
    }

    this.props.postDataLoad({
      title,
      files: [],
      new_audience:
        buildingObj && !buildingObj.soft_archived
          ? this.isAllowedGroup()
            ? [
                TO_NOT_SIGNEDUP_DWELLERS,
                TO_NOT_SIGNEDUP_LANDLORDS,
                TO_SIGNEDUP_DWELLERS,
                TO_SIGNEDUP_LANDLORDS,
              ]
            : [TO_SIGNEDUP_DWELLERS, TO_SIGNEDUP_LANDLORDS]
          : [],
      buildings: buildingObj && !buildingObj.soft_archived ? [buildingObj] : [],
      description: text,
      request: id,
      text,
    })
  }

  createRequest = async () => {
    const { request, isWizardEnabled } = this.props

    let data = { relatedRequests: [request] }
    let wizardData = { relatedRequest: request.id }

    let profile = null
    let building = null
    let flat = null

    const profileId = request.owner_obj?.id
    const buildigId = request.building
    const flatId = request.flat

    try {
      if (profileId) {
        profile = await getProfile(profileId)
      }
    } catch (e) {
      profile = null
    }

    try {
      if (buildigId) {
        building = await getBuilding(buildigId)
      }
    } catch (e) {
      building = null
    }

    try {
      if (flatId) {
        flat = await getFlat({ id: flatId })
      }
    } catch (e) {
      flat = null
    }

    if (profile && building && flat) {
      data.owner = {
        availableToManager: request.owner_obj.available_to_manager,
        avatar: request.owner_obj.avatar_obj.origin,
        deleted: request.owner_obj.deleted,
        flatsCount: profile.flats_count,
        group: request.owner_obj.group,
        id: request.owner_obj.id,
        name: request.owner_obj.name,
      }

      wizardData.user = request.owner_obj.id

      const {
        address_obj,
        construction_date,
        created,
        directory,
        dweller_count,
        flat_count,
        gk_obj,
        id,
        is_promo,
        owner_objs,
        photo_obj,
        property_owner_objs,
        soft_archived,
        updated,
      } = building

      data.building = {
        address_obj,
        construction_date,
        created,
        directory,
        dweller_count,
        flat_count,
        gk: gk_obj,
        id,
        is_promo,
        owner_objs,
        photo_obj,
        property_owner_objs,
        soft_archived,
        updated,
      }

      wizardData.building = request.building

      data.flat = flat

      wizardData.flat = request.flat
    }

    if (isWizardEnabled) {
      this.setState({
        wizardData,
      })

      return
    }

    this.props.requestDataLoad(data)
  }

  openDueDateModal = () => {
    this.props.openModal(<DueDatePopup />)
  }

  openLabelsModal = () => {
    const { request } = this.props

    this.props.openModal(
      <LabelsPopup
        infoData={request}
        onChange={this.props.updateLabels}
        onEdit={this.props.openModal}
      />
    )
  }

  openRemoveModal = () => {
    this.props.openModal(
      <ConfirmationPopup
        title={this.props.t('DeleteTitle')}
        text={this.props.t('DeleteWarningText')}
        onClose={this.props.hideModal}
        onOk={this.archiveRequest}
      />
    )
  }

  openRestoreModal = () => {
    this.props.openModal(
      <ConfirmationPopup
        title={this.props.t('RestoreTitle')}
        text={this.props.t('RestoreWarningText')}
        confirm={this.props.t('Common:Restore')}
        onClose={this.props.hideModal}
        onOk={() => this.archiveRequest(true)}
      />
    )
  }

  openMassEditModal = () => this.props.openMassEditModal()

  archiveRequest = is_restore => {
    const {
      request: { id },
    } = this.props
    updateRequest({
      model: 'request',
      model_pks: JSON.stringify([id]),
      patch: { soft_archived: !is_restore },
    }).then(() => {
      this.props.hideModal()
      this.props.initRequest(id)
    })
  }

  isAllowedGroup = () => {
    const { user } = this.props

    if (user && [USER_GROUPS.admin, USER_GROUPS.manager].includes(user.group)) {
      return true
    }

    return false
  }

  handleCloseWizard = () => {
    this.setState({ wizardData: null })
  }

  render() {
    const {
      request,
      match: { params },
      collapsed,
      activeWidget,
    } = this.props

    const { wizardData } = this.state
    const outbound = params?.outbound

    if (!request) {
      return null
    }

    const {
      id,
      permissions,
      address_obj: addressObj,
      flat_obj: flatObj,
      flat: flatId,
      building: buildingId,
    } = request

    const mapping = outbound
      ? { AddFile: true }
      : {
          AddDueDate: 'can_edit_due_date',
          AddLabel: 'can_edit_labels',
          AddChecklist: 'can_edit_checklists',
          AddFile: 'can_edit_add_files',
          AddFeedback: 'can_add_feedback',
          AddPost: 'can_edit_content',
          CreateRequestFromCurrentRequest: this.isAllowedGroup(),
          MassEditRelatedRequests: this.isAllowedGroup(),
          ['Common:Delete']: 'can_edit_due_date',
          ['Common:Restore']: 'can_restore',
        }

    const menuItems = [
      {
        icon: 'post',
        key: 'AddPost',
        callback: this.createPost,
      },
      {
        icon: 'deadline',
        key: 'AddDueDate',
        callback: this.openDueDateModal,
      },
      {
        icon: 'label',
        key: 'AddLabel',
        callback: this.openLabelsModal,
      },
      {
        icon: 'checklist',
        key: 'AddChecklist',
        callback: this.props.openChecklistsPopUp,
      },
      {
        icon: 'folder',
        key: 'AddFile',
        callback: this.updateFiles,
      },
      {
        icon: 'review',
        key: 'AddFeedback',
        callback: this.createFeedback,
      },
      {
        icon: 'request',
        key: 'CreateRequestFromCurrentRequest',
        callback: this.createRequest,
      },
      {
        icon: 'pencil',
        key: 'MassEditRelatedRequests',
        callback: this.openMassEditModal,
      },
      {
        icon: 'bin',
        key: 'Common:Delete',
        callback: this.openRemoveModal,
      },
      {
        icon: 'restore',
        key: 'Common:Restore',
        callback: this.openRestoreModal,
      },
    ].filter(item => {
      if (mapping[item.key] === true) {
        return true
      }

      return permissions[mapping[item.key]]
    })

    return (
      <>
        <Widgets collapsed={collapsed}>
          {!!menuItems.length && (
            <WidgetAddEntities
              menuItems={menuItems}
              outbound={outbound}
              title={this.props.t('Actions')}
            />
          )}
          {!outbound && (
            <WidgetParticipantsList
              requestId={id}
              params={params}
              isActive={activeWidget === WIDGET_TYPES.participants}
            />
          )}
          {!outbound && this.isAllowedGroup() && (
            <ProvidersWidgetContainer
              isActive={activeWidget === WIDGET_TYPES.providers}
            />
          )}
          {!outbound && this.isAllowedGroup() && buildingId && (
            <BuildingGroupWidget
              buildingId={buildingId}
              isActive={activeWidget === WIDGET_TYPES.buildingGroups}
            />
          )}
          {!outbound && (
            <AddressWidget
              address={addressObj}
              flat={flatObj}
              flatId={flatId}
              building={buildingId}
              isActive={activeWidget === WIDGET_TYPES.address}
            />
          )}
        </Widgets>
        {wizardData && (
          <NewWizard init={wizardData} onClose={this.handleCloseWizard} />
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  request: state.requestView.data,
  user: getUser(state),
  collapsed: isMenuCollapsed(state),
  postLoaded: state.post.loaded,
  requestLoaded: state.requestCreate.loaded,
  isWizardEnabled: isWizardEnabled(state),
  activeWidget: getActiveWidget(state),
})

const mapDispatchToProps = {
  openChecklistsPopUp: actions.openChecklistsPopUp,
  updateLabels: actions.updateLabels,
  updateFiles: actions.updateFiles,
  openModal: actions.openModal,
  openMassEditModal: actions.openMassEditModal,
  hideModal: actions.hideModal,
  initRequest: actions.initRequest,
  setWidget,
  push,
  postDataLoad,
  requestDataLoad,
}

export default compose(
  withTranslation('Request'),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RequestWidgets)
