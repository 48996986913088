// @flow

import React, { Component, Fragment, createRef } from 'react'
import { connect } from 'react-redux'
import { push, replace } from 'connected-react-router'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { stringify } from 'qs'
import {
  filter as filterArray,
  uniqBy,
  find,
  isEmpty,
  debounce,
  omit,
} from 'lodash-es'
import classnames from 'classnames'
import type { Location, Match } from 'react-router-dom'
import type { Node } from 'react'

import Checkbox from '../../Checkbox'
import {
  getFormattedDate,
  cutText,
  getUserName,
  isStaffUser,
  userCanCreateRequest,
} from '../../../utils/utils'
import { getCurrentFilters, getCurrentPage } from '../../../utils/routing'
import {
  getLocation,
  isMenuCollapsed,
  getUser,
  isWizardEnabled,
} from '../../../utils/commonSelectors'
import EmptyList from '../../EmptyList'
import { EntityIcon } from '../../Icon'
import Nbsp from '../../NBSP'
import ListItem from './ListItem'
import ExportRequestModal from './ExportRequestModal'
import ExportAsFilesModal from './ExportAsFilesModal'
import Modal from '../../Modal'
import Loader from '../../Loader'
import RequestStatusPopup from '../../modals/RequestStatus'
import UserPopup from '../../modals/UserPopup/UserPopup'
import ConfirmationPopup from '../../modals/ConfirmationPopup'
import TopPagination from '../../Pagination/TopPagination'
import BottomPagination from '../../Pagination/BottomPagination'
import Rating from '../../Rating'
import SelectCustom from '../../Select/SelectCustom'
import Table from '../../Table'
import Button from '../../Button'
import * as actions from './RequestList.actionTypes'
import { initRequest } from '../../../containers/RequestView/RequestView.actions'
import addRedirectToFirstPage from '../../../hocs/addRedirectToFirstPage'
import * as utils from './RequestList.utils'
import { REQUEST_TYPES_WITHOUT_RATING } from '../../../constants'
import { loadRequestsInvoices } from '../../../core/api/api.budgetPlan'
import NewWizard from '../../NewWizard'
import message from 'antd/es/message'
import ConnectRequestModal from './ConnectRequestModal'
import { disconnectRequest } from '../../../core/api/api.request'

import styles from './RequestList.module.scss'

type Props = {
  dispatch: Object => void,
  initiated: boolean,
  isMenuCollapsed: boolean,
  isWizardEnabled: boolean,
  location: Location,
  match: Match,
  meta: ?Object,
  modal: ?Node,
  params?: Object,
  permissions: ?Object,
  profileGroup: string,
  requests: ?Array<Object>,
  search: ?string,
  selected: Array<number>,
  setCount?: number => void,
  statuses: Array<Object>,
  t: (string, ?Object) => string,
  tabContext: ?string,
  tabView?: boolean,
  user: Object,
  viewOnly?: boolean,
  working: boolean,
}

type State = {
  horizontalScrollPosition: number,
  isWizardOpen: boolean,
  positionButtonHorizontalScroll: number,
}

function getAssigneeId(profile: Object) {
  // For workers we use fullname (email) as id
  return profile.id || profile.fullname
}

class RequestList extends Component<Props, State> {
  static defaultProps = {
    // Different style for requests in tab profile, building, flat
    tabView: true,
  }

  state = {
    horizontalScrollPosition: 0,
    positionButtonHorizontalScroll: utils.DEFAULT_POSITION_SCROLL_BUTTON,
    isWizardOpen: false,
  }

  scrollableRef = createRef()

  componentDidMount() {
    const { location, match, profileGroup } = this.props

    const page = getCurrentPage(location)
    const searchFilter = match.params.search
    const filters = getCurrentFilters(location)

    if (filters.page) {
      this.initiate({
        ...utils.getOwnerFilter(match.params.profileId, profileGroup),
        page,
        search: searchFilter ? decodeURIComponent(searchFilter) : searchFilter,
        ...filters,
        ...utils.getExtendedFilters(this.props.match),
      })
    }

    window.addEventListener('scroll', this.handleVerticalScroll)
  }

  componentDidUpdate(prevProps: Props) {
    const { search, match, location, initiated, working, meta } = this.props
    const page = getCurrentPage(location)
    const filters = getCurrentFilters(location)

    if (
      search !== prevProps.search ||
      match.params.search !== prevProps.match.params.search
    ) {
      this.initiate({
        ...prevProps.params,
        search: search ? decodeURIComponent(search) : search,
        page,
        ...filters,
        ...utils.getExtendedFilters(match),
      })
    }

    if (location.search !== prevProps.location.search && location.search) {
      this.setState({
        positionButtonHorizontalScroll: utils.DEFAULT_POSITION_SCROLL_BUTTON,
      })
      this.load(this.props)
    }

    if (
      (initiated !== prevProps.initiated && initiated) ||
      (working !== prevProps.working && !working)
    ) {
      this.setState({
        positionButtonHorizontalScroll: utils.getInitialPositionButtonScroll(),
      })
    }

    if (initiated && meta && this.props.setCount) {
      this.props.setCount(this.props.meta.count)
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.REQUEST_LIST_RESET_INITIAL_STATE })

    window.removeEventListener('scroll', this.handleVerticalScroll)
  }

  handleVerticalScroll = debounce(() => {
    this.setState({
      positionButtonHorizontalScroll: utils.getPositionButtonHorizontalScroll(
        this.scrollableRef.current
      ),
    })
  }, 50)

  patchRequest = patch => {
    const { location, selected, profileGroup, match, user } = this.props

    this.props.dispatch({
      type: actions.REQUEST_LIST_MASS_UPDATE,
      ids: selected,
      patch,
      params: {
        ...utils.getOwnerFilter(match.params.profileId, profileGroup),
        ...getCurrentFilters(location),
        ...utils.getExtendedFilters(match),
      },
      isStaff: isStaffUser(user),
      pathname: location.pathname,
      query: location.query,
    })
  }

  handleCloseWizard = () => {
    this.setState({ isWizardOpen: false })
  }

  showRestoreModal = () => {
    const { selected } = this.props

    const restore = () => {
      this.patchRequest({ soft_archived: false })
    }

    if (selected.length) {
      this.props.dispatch({
        type: actions.REQUEST_LIST_SHOW_MODAL,
        modal: (
          <ConfirmationPopup
            title={this.props.t('RestoreTitle')}
            text={this.props.t('RestoreWarningText')}
            confirm={this.props.t('Common:Restore')}
            onClose={this.hidePopup}
            onOk={restore}
          />
        ),
      })
    }
  }

  showRemoveModal = () => {
    const { selected } = this.props

    const remove = () => {
      this.patchRequest({ soft_archived: true })
    }

    if (selected.length) {
      this.props.dispatch({
        type: actions.REQUEST_LIST_SHOW_MODAL,
        modal: (
          <ConfirmationPopup
            title={this.props.t('DeleteTitle')}
            text={this.props.t('DeleteWarningText')}
            onClose={this.hidePopup}
            onOk={remove}
          />
        ),
      })
    }
  }

  getStatusById = (id: number) => {
    const { statuses } = this.props

    return find(statuses, { id })
  }

  open = id => {
    this.props.dispatch(push(`/request/${id}`))
  }

  initiate = (params: Object = {}) => {
    const { user } = this.props

    this.props.dispatch({
      type: actions.REQUEST_LIST_INITIATE,
      params,
      isStaff: isStaffUser(user),
    })
  }

  load = (props, removeCount) => {
    const { meta, search, match, location, profileGroup, user } = props

    const page = getCurrentPage(location)

    if (page > 1 && removeCount && meta) {
      const limit = meta?.limit
      const count = meta?.count

      if ((count % limit) - removeCount <= 0) {
        this.props.dispatch(
          replace(
            `${location.pathname}?${stringify({ ...location.query, page: 1 })}`
          )
        )

        return
      }
    }

    this.props.dispatch({
      type: actions.REQUEST_LIST_LOAD,
      params: {
        ...utils.getOwnerFilter(match.params.profileId, profileGroup),
        search: match.params.search
          ? decodeURIComponent(match.params.search)
          : search,
        ...getCurrentFilters(location),
        ...utils.getExtendedFilters(props.match),
        page: page || (meta && meta.currPage),
      },
      isStaff: isStaffUser(user),
    })
  }

  toggle = (e: Object) => {
    this.props.dispatch({
      type: actions.REQUEST_LIST_TOGGLE,
      id: parseInt(e.target.value, 10),
    })
  }

  toggleAll = () => {
    this.props.dispatch({
      type: actions.REQUEST_LIST_TOGGLE_ALL,
      isRequestsPage: this.isRequestsPage(),
      tabContext: this.props.tabContext,
    })
  }

  showStatusConfirm = (request: Object, status: Object) => {
    const { statuses } = this.props
    const { id } = request

    const statusTitle = statuses.find(s => s.id === status).title

    const text = this.props.t('UpdateStatusText', {
      old: this.props.t(this.getStatusById(request.status).title),
      updated: this.props.t(statusTitle),
      id: request.request_no ? request.request_no : id,
    })

    this.props.dispatch({
      type: actions.REQUEST_LIST_SHOW_MODAL,
      modal: (
        <ConfirmationPopup
          title={this.props.t('UpdateStatusTitle')}
          text={text}
          confirm={this.props.t('UpdateStatusButton')}
          onOk={() => this.updateStatus(status, [id])}
          onClose={this.hidePopup}
        />
      ),
    })
  }

  showMassActionConfirm = (handler, confirmText) => {
    this.props.dispatch({
      type: actions.REQUEST_LIST_SHOW_MODAL,
      modal: (
        <ConfirmationPopup
          confirm={confirmText || this.props.t('Common:Ok')}
          onOk={handler}
          onClose={this.hidePopup}
        />
      ),
    })
  }

  selectWorker = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.REQUEST_LIST_SHOW_MODAL,
      modal: (
        <UserPopup
          emailCheck
          onlyManagers
          notCloseOnSave
          data={[]}
          group={['admin', 'manager']}
          title={this.props.t('addMembers')}
          inputPlaceholder={this.props.t('UserPopup:ChooseAssigneePlaceholder')}
          okText={this.props.t('Assign')}
          onClose={this.hidePopup}
          onSave={params =>
            this.showMassActionConfirm(
              () => this.updateWorker(params),
              this.props.t('Assign')
            )
          }
        />
      ),
    })
  }

  selectStatus = (request, status) => {
    if (request && status) {
      this.props.dispatch({
        type: actions.REQUEST_LIST_SHOW_MODAL,
        modal: (
          <RequestStatusPopup
            count={1}
            value={status.id}
            onClose={this.hidePopup}
            onOk={selected => this.showStatusConfirm(request, selected)}
          />
        ),
      })

      return
    }

    const { selected, requests } = this.props

    let unique = filterArray(requests, req => selected.includes(req.id))
    unique = uniqBy(unique, req => req.status)

    const value = unique.length > 1 ? null : unique[0].status

    this.props.dispatch({
      type: actions.REQUEST_LIST_SHOW_MODAL,
      modal: (
        <RequestStatusPopup
          count={selected.length}
          value={value}
          onClose={this.hidePopup}
          onOk={(status, ids) =>
            this.showMassActionConfirm(
              () => this.updateStatus(status, ids),
              this.props.t('UpdateStatusButton')
            )
          }
        />
      ),
    })
  }

  hidePopup = () => {
    this.props.dispatch({ type: actions.REQUEST_LIST_HIDE_MODAL })
  }

  updateWorker = (params: Object) => {
    const patch = {}

    if (params.users) {
      patch.assignees = JSON.stringify(params.users.map(getAssigneeId))
    }

    const { selected, location, match, profileGroup, user } = this.props

    this.props.dispatch({
      type: actions.REQUEST_LIST_MASS_UPDATE,
      ids: selected,
      patch,
      params: {
        ...utils.getOwnerFilter(match.params.profileId, profileGroup),
        ...getCurrentFilters(location),
        ...utils.getExtendedFilters(match),
      },
      isStaff: isStaffUser(user),
    })
  }

  updateStatus = (status: number, ids: ?Array<number> = null) => {
    const { location, selected, profileGroup, match, user } = this.props

    this.props.dispatch({
      type: actions.REQUEST_LIST_MASS_UPDATE,
      ids: ids || selected,
      patch: { status },
      params: {
        ...utils.getOwnerFilter(match.params.profileId, profileGroup),
        ...getCurrentFilters(location),
        ...utils.getExtendedFilters(match),
      },
      isStaff: isStaffUser(user),
    })
  }

  create = () => {
    const { permissions, isWizardEnabled, location } = this.props
    const filters = getCurrentFilters(location)
    const openWizard = filters.outbound
      ? permissions.is_wizard_enabled
      : isWizardEnabled

    if (openWizard) {
      this.setState({ isWizardOpen: true })
    } else {
      this.props.dispatch(
        push(`/${filters.outbound ? 'connected_request' : 'request'}/create`)
      )
    }
  }

  connect = () => {
    const { params } = this.props

    const modal = (
      <ConnectRequestModal id={params.request} onClose={this.hidePopup} />
    )

    this.showModal(modal)
  }

  leave = () => {
    const { params } = this.props

    disconnectRequest(params.request, { requests: [params.request] }).finally(
      () => {
        this.hidePopup()
        this.load(this.props)
        this.props.dispatch(initRequest(params.request))
      }
    )
  }

  showLeaveModal = () => {
    const modal = (
      <ConfirmationPopup
        title={this.props.t('DisconnectCurrentRequestConfirmTitle')}
        text={this.props.t('DisconnectCurrentRequestConfirmText')}
        confirm={this.props.t('DisconnectCurrentRequestConfirmBtn')}
        cancel={this.props.t('Cancel')}
        onClose={this.hidePopup}
        onOk={this.leave}
      />
    )

    this.showModal(modal)
  }

  removeFromGroup = () => {
    const { params, selected } = this.props

    disconnectRequest(params.request, { requests: selected }).finally(() => {
      this.hidePopup()
      this.load(this.props, selected.length)
      this.props.dispatch(initRequest(params.request))
    })
  }

  showRemoveFromGroupModal = () => {
    const modal = (
      <ConfirmationPopup
        confirm={this.props.t('DisconnectRequestsConfirmBtn')}
        text={this.props.t('DisconnectRequestsConfirmText')}
        title={this.props.t('DisconnectRequestsConfirmTitle')}
        cancel={this.props.t('Cancel')}
        onClose={this.hidePopup}
        onOk={this.removeFromGroup}
      />
    )

    this.showModal(modal)
  }

  formOtherChecklistsString = (checklists: Array<Object>) => {
    const maxLength = 20

    return checklists
      .slice(4)
      .map(
        ({ checked_count: checkedCount, count, name }) =>
          `${checkedCount}/${count} ${cutText(name, maxLength)}`
      )
      .join(', ')
      .slice(0, -2)
  }

  getScrollButtonStyles = () => {
    const { positionButtonHorizontalScroll } = this.state

    return {
      top: positionButtonHorizontalScroll + '%',
    }
  }

  renderEmpty = (text: string, canAdd: boolean = false) => {
    return (
      <EmptyList
        embedded
        btnText={this.props.t('Add')}
        canAdd={canAdd}
        icon='request'
        title={text}
        onClick={this.create}
      />
    )
  }

  isProfilePage = () => this.props.location.pathname.includes('profile')

  isFlatPage = () => this.props.location.pathname.includes('/flat/')

  isRequestViewPage = () => this.props.location.pathname.includes('/request/')

  isRequestsPage = () =>
    this.props.location.pathname.split('/')[1] === 'requests'

  isBuildingGroupPage = () =>
    this.props.location.pathname.includes('building-group')

  isWide = () => {
    const { location } = this.props
    const filters = getCurrentFilters(location)

    return (
      !this.props.isMenuCollapsed && !this.isRequestsPage() && !filters.outbound
    )
  }

  isRatingEnabled = (rating, status) => {
    const { location } = this.props
    const filters = getCurrentFilters(location)

    return (
      !filters.outbound &&
      rating &&
      !REQUEST_TYPES_WITHOUT_RATING.includes(status)
    )
  }

  scrollToRight = () => {
    const current = this?.scrollableRef?.current

    if (!current) {
      return
    }

    const { clientWidth, scrollWidth } = current

    current.scrollLeft = scrollWidth - clientWidth
  }

  scrollToLeft = () => {
    const current = this?.scrollableRef?.current

    if (!current) {
      return
    }

    current.scrollLeft = 0
  }

  handleScroll = debounce(
    horizontalScrollPosition => this.setState({ horizontalScrollPosition }),
    100
  )

  isVisibleLeftArrow = () => this.state.horizontalScrollPosition !== 0

  isVisibleRightArrow = () => {
    const { current } = this.scrollableRef

    return (
      !current ||
      this.state.horizontalScrollPosition <
        current.scrollWidth - current.clientWidth - 5
    )
  }

  renderArrowButtons = () => {
    const { working } = this.props

    return (
      this.isWide() && (
        <Fragment>
          <Button.Arrow
            style={this.getScrollButtonStyles()}
            className={styles.scrollButton}
            hidden={working || !this.isVisibleLeftArrow()}
            onClick={this.scrollToLeft}
          />
          <Button.Arrow
            forward
            className={styles.scrollButton}
            style={this.getScrollButtonStyles()}
            hidden={working || !this.isVisibleRightArrow()}
            onClick={this.scrollToRight}
          />
        </Fragment>
      )
    )
  }

  showModal = modal => {
    this.props.dispatch({
      type: actions.REQUEST_LIST_SHOW_MODAL,
      modal,
    })
  }

  updateReadStatus = markAction => {
    const { location, selected } = this.props
    const filters = getCurrentFilters(location)

    markAction(selected, filters)
    this.hidePopup()
  }

  markAsRead = () => {
    this.updateReadStatus(this.markAsReadAction)
  }

  markAsUnread = () => {
    this.updateReadStatus(this.markAsUnreadAction)
  }

  loadInvoices = () => {
    loadRequestsInvoices().finally(() => {
      this.hidePopup()
    })
  }

  markAsReadAction = (selected, filters) => {
    const { user } = this.props

    this.props.dispatch({
      type: actions.REQUEST_LIST_MARK_AS_READ,
      ids: selected,
      params: { ...filters, ...utils.getExtendedFilters(this.props.match) },
      isStaff: isStaffUser(user),
    })
  }

  markAsUnreadAction = (selected, filters) => {
    const { user } = this.props

    this.props.dispatch({
      type: actions.REQUEST_LIST_MARK_AS_UNREAD,
      ids: selected,
      params: { ...filters, ...utils.getExtendedFilters(this.props.match) },
      isStaff: isStaffUser(user),
    })
  }

  showMarkAsReadModal = () => {
    const modal = (
      <ConfirmationPopup
        confirm={this.props.t('MarkAsRead')}
        cancel={this.props.t('Cancel')}
        onClose={this.hidePopup}
        onOk={this.markAsRead}
      />
    )

    this.showModal(modal)
  }

  showMarkAsUnreadModal = () => {
    const modal = (
      <ConfirmationPopup
        confirm={this.props.t('MarkAsUnread')}
        cancel={this.props.t('Cancel')}
        onClose={this.hidePopup}
        onOk={this.markAsUnread}
      />
    )

    this.showModal(modal)
  }

  showRequestInvoicesModal = () => {
    const modal = (
      <ConfirmationPopup
        confirm={this.props.t('Load')}
        cancel={this.props.t('Cancel')}
        text={this.props.t('InvoicesReportGeneratingText')}
        onClose={this.hidePopup}
        onOk={this.loadInvoices}
      />
    )

    this.showModal(modal)
  }

  showExportRequestModal = (as_files = false) => {
    const { selected, location } = this.props
    const filters = getCurrentFilters(location)
    const export_filters = {
      ...omit(filters, ['page']),
      request_ids: selected.length > 0 ? selected : undefined,
    }

    const ModalComponent = as_files ? ExportAsFilesModal : ExportRequestModal

    const modal = (
      <ModalComponent
        filters={export_filters}
        onClose={this.hidePopup}
        onSuccess={() => message.success(this.props.t('ExportStarted'))}
        onError={error => message.error(error || this.props.t('ExportError'))}
      />
    )
    this.showModal(modal)
  }

  getMassActionOptions = () => {
    return [
      {
        value: 'selectWorker',
        label: this.props.t('Assign'),
        handler: () => this.selectWorker(),
        icon: 'purpose',
      },
      {
        value: 'selectStatus',
        label: this.props.t('StatusChange'),
        handler: () => this.selectStatus(),
        icon: 'status',
      },
      {
        value: 'markAsRead',
        label: this.props.t('MarkAsRead'),
        handler: () => this.showMarkAsReadModal(),
        icon: 'requestRead',
      },
      {
        value: 'markAsUnread',
        label: this.props.t('MarkAsUnread'),
        handler: () => this.showMarkAsUnreadModal(),
        icon: 'requestUnread',
      },
    ]
  }

  getExportOptions = () => {
    const { selected } = this.props

    let export_options = [
      {
        value: 'exportRequests',
        label: this.props.t('LoadAllRequests'),
        handler: () => this.showExportRequestModal(),
        icon: 'upload',
      },
      {
        value: 'requestInvoices',
        label: this.props.t('LoadAllRequestsInvoices'),
        handler: () => this.showRequestInvoicesModal(),
        icon: 'budget',
      },
    ]

    if (selected.length > 0) {
      export_options.push({
        value: 'exportRequestsAsFiles',
        label: this.props.t('ExportAsFiles'),
        handler: () => this.showExportRequestModal(true),
        icon: 'folder',
      })
    }

    return export_options
  }

  preventOpen = e => {
    e.stopPropagation()
  }

  renderTable = () => {
    const { working, requests, user, location } = this.props
    const isProfilePage = this.isProfilePage()
    const isBuildingGroupPage = this.isBuildingGroupPage()
    const filters = getCurrentFilters(location)
    const isWide = this.isWide()
    const tableClass = classnames('table__wrapper table__requests-list', {
      'table__requests-list-wide': isProfilePage,
      [styles.wide]: isWide,
    })
    const outerClass = classnames('table__outer-wrapper', {
      [styles.scrollable]: isWide && !isEmpty(requests),
      [styles.unscrollable]: isEmpty(requests),
    })
    const requestHeaderCell = (
      <Table.HeaderCell
        title={this.props.t('Ticket')}
        sortKey='request_no'
        working={working}
        style={{ width: isBuildingGroupPage ? '30%' : '35%' }}
      />
    )
    const authorHeaderCell = (
      <Table.HeaderCell
        title={this.props.t('Author')}
        sortKey='fullname'
        working={working}
        style={{ width: '15%' }}
      />
    )
    const updatedField =
      user.group === 'dweller' || filters.outbound
        ? 'updated'
        : 'private_updated'

    return (
      <div
        className={outerClass}
        ref={this.scrollableRef}
        onScroll={e => this.handleScroll(e.target.scrollLeft)}
      >
        <div className={tableClass}>
          <div className='table__head'>
            {requestHeaderCell}
            {isProfilePage ? (
              <Fragment>
                <Table.HeaderCell
                  title={this.props.t('Address')}
                  sortKey='address'
                  working={working}
                  style={{ width: '20%' }}
                />
                <Table.HeaderCell
                  title={this.props.t('FlatNumber')}
                  sortKey='flat_number'
                  working={working}
                  style={{ width: '10%' }}
                />
              </Fragment>
            ) : (
              <Table.HeaderCell
                title={this.props.t('Address')}
                sortKey='address'
                working={working}
                style={{ width: isBuildingGroupPage ? '20%' : '30%' }}
              />
            )}
            {authorHeaderCell}
            {isBuildingGroupPage && (
              <Table.HeaderCell
                title={this.props.t('Managers')}
                working={working}
                style={{ width: '15%' }}
              />
            )}
            <Table.HeaderCell
              title={this.props.t('Status')}
              sortKey='status'
              working={working}
              style={{ width: '10%' }}
            />
            <Table.HeaderCell
              title={this.props.t('Updated')}
              sortKey={updatedField}
              working={working}
              style={{ width: '10%' }}
            />
          </div>
          {!isEmpty(requests) && this.renderArrowButtons()}
          {!working && requests.map(this.renderRequest)}
        </div>
      </div>
    )
  }

  renderFlatTable = () => {
    const { working, isMenuCollapsed, requests, user } = this.props
    const isFlatPage = this.isFlatPage()
    const tableClass = classnames(
      'table__wrapper table__requests-list table__requests-list--flat',
      {
        [styles.wide]: !isMenuCollapsed,
      }
    )
    const outerClass = classnames('table__outer-wrapper', {
      [styles.scrollable]: !isMenuCollapsed && !isEmpty(requests),
      [styles.unscrollable]: isEmpty(requests),
    })

    const updatedField =
      user.group === 'dweller' ? 'updated' : 'private_updated'

    return (
      <div
        className={outerClass}
        ref={this.scrollableRef}
        onScroll={e => this.handleScroll(e.target.scrollLeft)}
      >
        <div className={tableClass}>
          <div className='table__head'>
            {!isFlatPage && (
              <Table.HeaderCell
                title={this.props.t('FlatNumber')}
                sortKey='flat_number'
                working={working}
              />
            )}
            <Table.HeaderCell
              title={this.props.t('Author')}
              sortKey='fullname'
              working={working}
            />
            <Table.HeaderCell
              title={this.props.t('Ticket')}
              sortKey='request_no'
              working={working}
            />
            <Table.HeaderCell
              title={this.props.t('Assignee')}
              sortKey='assignees_count'
              working={working}
            />
            <Table.HeaderCell
              title={this.props.t('Status')}
              sortKey='status'
              working={working}
            />
            <Table.HeaderCell
              title={this.props.t('Updated')}
              sortKey={updatedField}
              working={working}
            />
          </div>
          {!working && requests.map(this.renderFlatRequest)}
          {!isEmpty(requests) && this.renderArrowButtons()}
        </div>
      </div>
    )
  }

  renderSelectStatus = (request: Object, status: Object) => {
    const { statuses } = this.props

    if (!statuses.length) {
      return null
    }

    return (
      <div className='table-extra'>
        <Button.Save
          className='button--medium button--no-right-margin'
          onClick={e => {
            e.stopPropagation()

            this.selectStatus(request, status)
          }}
        >
          {this.props.t('StatusChange')}
        </Button.Save>
      </div>
    )
  }

  renderOwnerCell = owner => {
    const ownerFullName = getUserName(owner)

    const textClass = classnames('table-item__user-name', styles.textOverflow)

    return (
      <Fragment>
        <i className='table-item__user-pic'>
          <img src={owner ? owner.avatar : null} alt='author' />
        </i>
        <span className={textClass} title={ownerFullName}>
          {ownerFullName}
        </span>
      </Fragment>
    )
  }

  renderRequest = (request: Object) => {
    const { selected, user, location, viewOnly } = this.props
    const { id, status, archived } = request
    const filters = getCurrentFilters(location)

    return (
      <ListItem
        canCheck={
          !viewOnly &&
          !filters.outbound &&
          user.group !== 'dweller' &&
          (!archived || (!this.isProfilePage() && !this.isBuildingGroupPage()))
        }
        checked={selected.includes(id)}
        data={request}
        isDweller={user.group === 'dweller'}
        key={id}
        outbound={filters.outbound}
        profileView={this.isProfilePage()}
        buildingGroupView={this.isBuildingGroupPage()}
        renderSelectStatus={this.renderSelectStatus}
        requestView={this.isRequestViewPage()}
        status={this.getStatusById(status)}
        toggle={this.toggle}
      />
    )
  }

  renderFlatRequest = (request: Object) => {
    const { selected, user } = this.props
    const {
      id,
      updated,
      private_updated,
      archived,
      request_no: number,
      assignees,
      is_unviewed: unviewed,
      flat_obj: flat,
      owner,
      title,
      rating,
    } = request

    const {
      permissions: { can_edit_status: canEditStatus },
    } = request
    const assignee = assignees?.length > 0 ? assignees[0] : null
    const assigneeFullName = getUserName(assignee)
    const status = this.getStatusById(request.status)
    const key = `request-${id}`
    const checked = selected.includes(id)
    const itemClass = classnames('table__row request-list-item', {
      'table__row--attention': checked,
      'table__row--not-viewed': unviewed,
      'table__row--archived': archived,
    })
    const assigneesWithoutFirst = [...(assignees || [])]
    assigneesWithoutFirst.shift()
    const assigneesTitle = assigneesWithoutFirst
      .map(obj => `${obj.name}${obj.second_name ? ` ${obj.second_name}` : ''}`)
      .join(', ')
    const isFlatPage = this.isFlatPage()
    const tableItemClass = classnames('table-item', {
      'table-item__user': isFlatPage,
      [styles.archivedOwner]: archived && user.group === 'dweller',
    })
    const labelStyle = { paddingLeft: '0' }

    return (
      <div className={itemClass} key={key} id={key}>
        <div className='table__cell'>
          <div className='table__cell-wrapper' onClick={() => this.open(id)}>
            <div className={tableItemClass}>
              {user.group !== 'dweller' && !archived ? (
                <label
                  htmlFor={`check-request-${id}`}
                  className='checkbox checkbox--medium checkbox--default checkbox--notext table-item__checkbox'
                  style={!isFlatPage ? labelStyle : {}}
                  onClick={e => e.stopPropagation()}
                >
                  <input
                    type='checkbox'
                    value={id}
                    id={`check-request-${id}`}
                    checked={checked}
                    onChange={this.toggle}
                  />
                  <span />
                </label>
              ) : (
                ''
              )}
              &nbsp;
              {!isFlatPage ? (
                <span
                  className={classnames(
                    styles.textOverflow,
                    styles.flatNumber,
                    { [styles.archivedFlatNumber]: archived }
                  )}
                >
                  {flat && flat.number}
                </span>
              ) : (
                this.renderOwnerCell(owner)
              )}
            </div>
          </div>
        </div>
        {!isFlatPage && (
          <div className='table__cell'>
            <div className='table__cell-wrapper'>
              <div
                className={classnames('table-item', 'table-item__user', {
                  [styles.archivedOwner]: archived,
                })}
              >
                {this.renderOwnerCell(owner)}
              </div>
            </div>
          </div>
        )}
        <div className='table__cell' onClick={() => this.open(id)}>
          <div className='table__cell-wrapper'>
            <div
              className={`table-item table-item__request ${
                user.group === 'dweller' ? 'table-item__text' : ''
              }`}
            >
              <span className='requestlist__request-title'>
                <EntityIcon id='request' className={styles.entityIcon} />
                {this.props.t('request_number_title')} {number}
              </span>
              {this.isRatingEnabled(rating, request.status) && (
                <Rating readonly value={rating} />
              )}
              <span className='requestlist__request-text' title={title}>
                {title}
              </span>
            </div>
          </div>
        </div>
        <div className='table__cell'>
          <div className='table__cell-wrapper' onClick={() => this.open(id)}>
            {assignees?.length > 0 ? (
              <div className='table-item table-item__user'>
                <i className='table-item__user-pic'>
                  <img src={assignee ? assignee.avatar : null} alt='author' />
                </i>
                <span
                  className='table-item__user-name table-item__user-name--flat-requests'
                  title={assigneeFullName}
                  style={{
                    maxWidth: assignees.length > 1 ? '11.5rem' : '14rem',
                  }}
                >
                  {assigneeFullName}
                </span>
                {assignees.length > 1 ? (
                  <i
                    className='badge badge--medium badge--table-request-assignee'
                    title={`${assigneesTitle}`}
                  >
                    {`+${assignees.length - 1}`}
                  </i>
                ) : null}
              </div>
            ) : (
              <div className='table-item'>
                <span className='table-item__text table-item__text'>
                  {this.props.t('NotAssigned')}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className='table__cell' onClick={() => this.open(id)}>
          <div className='table__cell-wrapper'>
            <div
              title={this.props.t(status.title)}
              className={`table-item table-item__status table-item__status--${status.css}`}
            >
              <span>{this.props.t(status.title)}</span>
            </div>
          </div>
        </div>
        <div className='table__cell table__cell--text-right'>
          <div className='table__cell-wrapper'>
            {canEditStatus && this.renderSelectStatus(request, status)}
            <div
              className='table-item table-item__text'
              title={getFormattedDate(
                user.group === 'dweller' ? updated : private_updated
              )}
            >
              <span>
                {getFormattedDate(
                  user.group === 'dweller' ? updated : private_updated
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCreateRequestButton = () => {
    const { tabContext, requests, viewOnly } = this.props

    if (viewOnly) {
      return null
    }

    if (tabContext === 'request') {
      return (
        <>
          <div className='toolbox__cell'>
            <Button.Save icon='request' onClick={this.connect}>
              {this.props.t('ConnectRequestBtn')}
            </Button.Save>
          </div>
          {requests && !!requests.length && (
            <div className='toolbox__cell'>
              <Button.Save icon='request' onClick={this.showLeaveModal}>
                {this.props.t('DisconnectCurrentRequestBtn')}
              </Button.Save>
            </div>
          )}
        </>
      )
    }

    return (
      <div className='toolbox__cell'>
        <Button.Save icon='request' onClick={this.create}>
          {this.props.t('Create')}
        </Button.Save>
      </div>
    )
  }

  render() {
    const {
      permissions,
      requests,
      meta,
      user,
      search,
      selected,
      working,
      modal,
      params,
      initiated,
      tabView,
      location,
      tabContext,
      viewOnly,
    } = this.props

    const title = search
      ? `${this.props.t('SearchResults')}:`
      : this.props.t('ListTitle')

    let fullTitleText = title.trim()

    if (search) {
      fullTitleText += ` ${decodeURIComponent(search)} `
    }

    fullTitleText = fullTitleText.trim()

    const sectionClass = classnames(
      { 'unit--nomargin': !search && tabView },
      { 'unit--default bar': search },
      {
        'unit unit--default bar': tabView,
      }
    )

    if (!initiated) {
      return tabView && !viewOnly ? (
        <section className={sectionClass}>
          <h2 className='unit__title'>{fullTitleText}</h2>
          <Loader type='medium' text={false} />
        </section>
      ) : (
        <Loader type='big' text={false} />
      )
    }

    let canCreate = false
    let canDelete = false
    let canRestore = false
    let canEditStatus = false

    if (permissions) {
      const { can_create, can_delete, can_restore, can_edit_status } =
        permissions

      canCreate = can_create && userCanCreateRequest(user)
      canDelete = can_delete
      canRestore = can_restore
      canEditStatus = can_edit_status
    }

    const { isWizardOpen } = this.state

    const filters = getCurrentFilters(location)
    const emptyFilter = isEmpty(filters)

    if (!working && meta && !meta.count && emptyFilter) {
      const canAdd = !search && canCreate
      let text = `${this.props.t(search ? 'SearchNotFound' : 'NotFound')} `

      if (canAdd) {
        text += this.props.t('AddFirst')
      }

      return (
        <section className={sectionClass}>
          {!viewOnly && <h2 className='unit__title'>{fullTitleText}</h2>}
          {this.renderEmpty(text, canAdd)}
        </section>
      )
    }

    const isRequestsPage = this.isRequestsPage()

    const activeRequests =
      (requests && requests.filter(r => !r.archived || isRequestsPage)) || []

    const currentRequests = tabContext === 'request' ? requests : activeRequests

    return (
      <section className={sectionClass}>
        {!viewOnly && (tabView || search) && (
          <h2 className='unit__title'>
            {fullTitleText}
            <Nbsp />
            {meta && meta.count > 0 && (
              <span className='unit__title-num'>{meta.count}</span>
            )}
          </h2>
        )}
        {meta && meta.count > 0 && (
          <div className='toolbox'>
            {!viewOnly &&
            !filters.outbound &&
            user.group !== 'dweller' &&
            currentRequests.length ? (
              <div className='toolbox__cell'>
                <Checkbox
                  outer
                  checked={selected.length === currentRequests.length}
                  onChange={this.toggleAll}
                />
              </div>
            ) : (
              ''
            )}
            {canCreate &&
              selected.length === 0 &&
              this.renderCreateRequestButton()}

            {canEditStatus && selected.length > 0 && (
              <div className='toolbox__cell'>
                <SelectCustom
                  options={this.getMassActionOptions()}
                  style={{ marginRight: '-10px' }}
                  onClick={this.preventOpen}
                  onChange={opt => opt.handler()}
                >
                  {this.props.t('Actions')}
                </SelectCustom>
              </div>
            )}

            {canRestore && (
              <div className='toolbox__cell'>
                <Button.Cancel
                  disabled={!selected.length}
                  icon='restore'
                  onClick={this.showRestoreModal}
                >
                  {this.props.t('Common:Restore')}
                </Button.Cancel>
              </div>
            )}
            {canDelete && selected.length > 0 && (
              <div className='toolbox__cell'>
                {tabContext === 'request' ? (
                  <Button.Remove onClick={this.showRemoveFromGroupModal}>
                    {this.props.t('DisconnectRequestsBtn')}
                  </Button.Remove>
                ) : (
                  <Button.Remove onClick={this.showRemoveModal} />
                )}
              </div>
            )}
            {!tabView && isStaffUser(user) && !filters.outbound && (
              <div className='toolbox__cell'>
                <SelectCustom
                  colored
                  options={this.getExportOptions()}
                  style={{ marginRight: '-10px' }}
                  onClick={this.preventOpen}
                  onChange={opt => opt.handler()}
                >
                  {this.props.t('Export')}
                </SelectCustom>
              </div>
            )}
            <div className='toolbox__cell toolbox__cell--right'>
              <TopPagination
                classes='pager pager--large pager--default-2'
                meta={meta}
              />
            </div>
          </div>
        )}
        {meta && meta.count === 0 && canCreate && selected.length === 0 && (
          <div className='toolbox'>{this.renderCreateRequestButton()}</div>
        )}
        <div
          className='table table--default table--01'
          style={{ position: 'relative' }}
        >
          {params && (params.flat || params.building)
            ? this.renderFlatTable()
            : this.renderTable()}
          {working && <Loader type='medium' text={false} />}
          {!working &&
            requests &&
            !requests.length &&
            this.renderEmpty(this.props.t('NotFound'), filters.outbound)}
        </div>
        <BottomPagination classes='paginator-2' meta={meta} />
        <Modal
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!modal}
          onRequestClose={this.hidePopup}
        >
          {modal}
        </Modal>
        {isWizardOpen && (
          <NewWizard
            init={
              filters.outbound
                ? { user: this.props.inbound_user }
                : this.props.init
            }
            outbound={filters.outbound}
            onClose={this.handleCloseWizard}
          />
        )}
      </section>
    )
  }
}

const mapStateToProps = state => ({
  ...state.requestList,
  user: getUser(state),
  inbound_user: state.requestCreate.data?.inbound_user,
  statuses: state.init.request_status,
  isWizardEnabled: isWizardEnabled(state),
  location: getLocation(state),
  profileGroup: state.profile.data.group,
  isMenuCollapsed: isMenuCollapsed(state),
})

export default compose(
  withTranslation('Request'),
  withRouter,
  connect(mapStateToProps),
  addRedirectToFirstPage
)(RequestList)
