// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import type { Node } from 'react'

import { isEmpty, uniqueId } from 'lodash-es'

import {
  getMassEditRequestFields,
  updateRelatedRequests,
  asyncMassActionTask,
} from '../../../core/api/api.request'

import { initRequest } from '../../../containers/RequestView/RequestView.actions'

import Modal from '../../../components/Modal'
import ModalBody from '../../../components/Modal/ModalBody'
import ModalRow from '../../../components/Modal/ModalRow'
import ModalCloseButton from '../../../components/Modal/ModalCloseButton'
import ModalHead from '../../../components/Modal/ModalHead'
import ModalButtons from '../../../components/Modal/ModalButtons'
import Button from '../../../components/Button'
import { useOverflow } from '../../../hooks'

import Warning from '../../../components/Warning'

import styles from './RequestMassEditModal.module.scss'

import RelatedRequestsModal from '../RelatedRequestsModal'
import RequestField from './RequestField'

const CHECK_UPDATE_TASK_INTERVAL = 2000
const STATUS_UPDATE_COMPLETE = 2

const RequestMassEditModal = (props): Node => {
  const { count, requestId, onClose } = props

  const [payload, setPayload] = useState({})
  const [fields, setFields] = useState([])
  const [working, setWorking] = useState(false)

  const { t } = useTranslation('Request')
  const dispatch = useDispatch()

  useOverflow()

  const [relatedModal, setRelatedModal] = useState(null)

  useEffect(() => {
    getMassEditRequestFields().then(data => setFields(data.fields))
  }, [])

  const openRelatedRequestsModal = () => {
    setRelatedModal(
      <RelatedRequestsModal
        requestId={requestId}
        onClose={() => setRelatedModal(null)}
      />
    )
  }

  const newEmptyField = {
    command: null,
    value: '',
  }

  const handleAddField = () => {
    let newPayload = { ...payload, [`emptyField${uniqueId()}`]: newEmptyField }
    setPayload(newPayload)
  }

  const handleChangeFieldKey = (field, newField) => {
    let newPayload = { ...payload, [newField]: payload[field] }
    delete newPayload[field]
    setPayload(newPayload)
  }

  const handleChangeField = (field, key, value) => {
    if (payload[field]) {
      let newPayload = {
        ...payload,
        [field]: { ...payload[field], [key]: value },
      }
      setPayload(newPayload)
    }
  }

  const handleRemoveField = field => {
    let newPayload = { ...payload }
    delete newPayload[field]
    setPayload(newPayload)
  }

  const handleUpdate = () => {
    setWorking(true)
    updateRelatedRequests(requestId, {
      payload,
      exclude_archived: false,
    }).then(data => {
      checkUpdateTask(data.task_id)
    })
  }

  const checkUpdateTask = taskId => {
    asyncMassActionTask(taskId).then(data => {
      if (data.status !== STATUS_UPDATE_COMPLETE) {
        setTimeout(() => checkUpdateTask(taskId), CHECK_UPDATE_TASK_INTERVAL)
      } else {
        setWorking(false)
        dispatch(initRequest(requestId))
        onClose()
      }
    })
  }

  const validPayload = () => {
    if (isEmpty(payload)) {
      return false
    }

    for (let field in payload) {
      if (!payload[field].command) {
        return false
      }

      if (payload[field].command !== 'clear') {
        if (
          !payload[field].value ||
          (Array.isArray(payload[field].value) && !payload[field].value.length)
        ) {
          return false
        }
      }
    }

    return true
  }

  return (
    <>
      <Modal
        isOpen
        style={{
          content: { minWidth: '732px', maxWidth: '732px' },
        }}
        onRequestClose={onClose}
      >
        <ModalHead title={t('MassEditRequestsTitle')} />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          style={{ minHeight: '380px', maxHeight: '604px', overflow: 'auto' }}
        >
          <ModalRow>
            <Warning
              noArrow
              link={t('ViewList')}
              text={t('ReviewRelatedRequests', { count })}
              boxClassName={styles.warningBox}
              className={styles.warning}
              onClick={openRelatedRequestsModal}
            />
          </ModalRow>
          <ModalRow>
            <Warning
              noArrow
              text={t('LongOperationWarning')}
              boxClassName={styles.warningBox}
              className={styles.warning}
            />
          </ModalRow>
          {Object.keys(payload).map(field => (
            <RequestField
              key={field}
              field={{
                name: field,
                command: payload[field].command,
                value: payload[field].value,
              }}
              fields={fields}
              payload={payload}
              onChange={handleChangeField}
              onChangeField={handleChangeFieldKey}
              onRemove={handleRemoveField}
            />
          ))}
          <ModalRow>
            <Button.Add title={t('AddMoreFields')} onClick={handleAddField} />
          </ModalRow>
        </ModalBody>
        <ModalButtons>
          <Button.Save
            disabled={!validPayload()}
            working={working}
            onClick={handleUpdate}
          >
            {t('Common:Ok')}
          </Button.Save>
          <Button.Cancel onClick={onClose}>{t('Common:Cancel')}</Button.Cancel>
        </ModalButtons>
      </Modal>
      {relatedModal && (
        <Modal
          isOpen
          style={{
            content: { minWidth: '732px', maxWidth: '732px' },
          }}
          onRequestClose={() => setRelatedModal(null)}
        >
          {relatedModal}
        </Modal>
      )}
    </>
  )
}

export default RequestMassEditModal
