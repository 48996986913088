// @flow

import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { DocumentFilePreview, DocumentVideoPreview } from '../../Documents'
import Image from '../../ImageSquareFit'
import TileList from '../../TileList'
import Button from '../../Button'
import Icon from '../../Icon'

import { isMediaFile, isVideoFile, downloadArchive } from '../../../utils/file'

import Lightbox from '../../LightboxGallery'

import styles from './MessageAttachments.module.scss'

const BOX_INDENT = 10
const ITEM_INDENT = 10
const ROW_LENGTH = 4
const THUMBNAIL_SIZE = 340

type Props = {
  canEdit: boolean,
  files: Array<Object>,
  outbound?: string,
}
type State = {
  downloading: boolean,
  lightboxIndex: ?number,
}

class MessageAttachments extends Component<Props, State> {
  state = {
    downloading: false,
    lightboxIndex: null,
  }

  openLightbox = (lightboxIndex: number) => () => {
    this.setState({ lightboxIndex })
  }

  closeLightbox = () => {
    this.setState({ lightboxIndex: null })
  }

  handleMassDownload = () => {
    if (!this.state.downloading) {
      this.setState({ downloading: true })
      downloadArchive(this.props.files.map(f => f.id)).then(res => {
        if (res) {
          window.open(res)
        }

        this.setState({ downloading: false })
      })
    }
  }

  renderFilename = ({ filename }: Object) => (
    <span className={styles.filename} title={filename}>
      {filename}
    </span>
  )

  renderMedia = (file: Object) => {
    const { thumbnails, origin } = file

    const thumbnail = thumbnails[THUMBNAIL_SIZE]

    if (isVideoFile(file)) {
      return <DocumentVideoPreview data={file} />
    }

    return <Image alt={file.filename} src={thumbnail || origin} />
  }

  renderItem = (file: Object, index: number) => {
    const { canEdit } = this.props
    const canDelete = canEdit && this.props.handleDeleteFile
    const { id } = file

    if (isMediaFile(file)) {
      return (
        <div className={styles.boxWrapper} key={id}>
          {canDelete && (
            <Button.Close
              className={styles.close}
              onClick={this.props.handleDeleteFile(file)}
            />
          )}
          <div
            className={styles.media}
            key={file.id}
            onClick={this.openLightbox(index)}
          >
            {this.renderMedia(file)}
            {this.renderFilename(file)}
          </div>
        </div>
      )
    }

    return (
      <div className={styles.boxWrapper} key={id}>
        {canDelete && (
          <Button.Close
            className={styles.close}
            onClick={this.props.handleDeleteFile(file)}
          />
        )}
        <div
          className={styles.document}
          key={file.id}
          onClick={this.openLightbox(index)}
        >
          <DocumentFilePreview canHover data={file} />
          {this.renderFilename(file)}
        </div>
      </div>
    )
  }

  render() {
    const { files, scope, object, outbound } = this.props
    const { lightboxIndex } = this.state

    return (
      <div className={styles.attachments}>
        {files.length > 1 && (
          <div className={styles.attachmentsHeader}>
            <div className={styles.count}>{`${this.props.t('Attachments')}: ${
              files.length
            }`}</div>
            <div className={styles.download} onClick={this.handleMassDownload}>
              <Icon id={this.state.downloading ? 'loader' : 'download'} />
              <span>{this.props.t('DownloadArchive')}</span>
            </div>
          </div>
        )}
        <TileList
          boxIndent={BOX_INDENT}
          itemIndent={ITEM_INDENT}
          rowLength={ROW_LENGTH}
        >
          {files.map(this.renderItem)}
        </TileList>
        <Lightbox
          newGallery
          object={object}
          activeIndex={lightboxIndex}
          images={files}
          scope={scope}
          outbound={outbound}
          onClose={this.closeLightbox}
        />
      </div>
    )
  }
}

export default compose(connect(), withTranslation('Mail'))(MessageAttachments)
