// @flow

import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get, has, partial } from 'lodash-es'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import type { Node } from 'react'
import moment from 'moment'

import Block, { Row } from '../../../components/Block'
import Modal from '../../../components/Modal'
import Warning from '../../../components/Warning'
import InfoHeader from '../InfoHeader'
import Assignees from '../../../components/Request/RequestInfo/Assignees'
import Orders from '../../../components/Request/RequestInfo/Orders'
import ButtonsBar from '../../../components/Request/RequestInfo/ButtonsBar'
import DueDate from '../../../components/Request/RequestInfo/DueDate'
import Main from '../../../components/Request/RequestInfo/Main'
import OwnerAddress from '../../../components/Request/RequestInfo/OwnerAddress'
import RequestOwnerName from '../../../components/Request/RequestInfo/RequestOwnerName'
import RequestType from '../../../components/Request/RequestInfo/RequestType'
import RelatedRequestsModal from '../RelatedRequestsModal'
import LabelsRow from '../../../components/Labels/LabelsRow'
import {
  COMPLETED_REQUEST_STATUS,
  IN_PROGRESS_REQUEST_STATUS,
  NOT_CONFIRMED_STATUS,
  USER_STATUS,
} from '../../../constants'
import { editPost } from '../../../core/api/api.newsband'
import * as selectors from '../Info.selectors'
import {
  //getUkConfig,
  getUser,
  showMarketplace,
} from '../../../utils/commonSelectors'
import {
  getUrlForAvatar,
  getUserName,
  isDwellerUser,
  isStaffUser,
} from '../../../utils/utils'
import * as actions from '../RequestView.actions'
import RequestProviders from '../../../components/RequestProviders'
import RequestManagers from '../../../components/Request/RequestManagers'
import { getInfoParams } from './RequestViewInfo.utils'
import RequestPipeline from './RequestPipeline'
import RequestCategoryConfirm from './RequestCategoryConfirm'

import styles from '../InfoHeader.module.scss'

type Props = {
  hideModal: () => void,
  isDweller: boolean,
  outbound?: string,
  relatedRequestsCount: number,
  requestData: Object,
  setModal: Node => void,
  setPipelineSwitched: boolean => void,
  working: boolean,
}

const MAX_DWELLERS_PREVIEW = 2

const RequestViewInfo = (props: Props): Node => {
  const { outbound, requestData, relatedRequestsCount, working, isDweller } =
    props

  const { t } = useTranslation('Request')

  const [confirmModal, setConfirmModal] = useState(false)
  const [postsToOffline, setPostsToOffline] = useState([])

  const closeModal = () => setConfirmModal(false)

  const dispatch = useDispatch()

  const updateInfo = (id, params) => dispatch(actions.updateInfo(id, params))

  const updateLabels = labels => dispatch(actions.updateLabels(labels))

  const isMarketplaceEnabled = useSelector(showMarketplace)
  const user = useSelector(getUser)
  const initialState = useSelector(selectors.getInitialState)
  const activeModal = useSelector(selectors.getActiveModal)
  //const ukConfig = useSelector(getUkConfig)
  //const { show_contractors_in_requests_for_all: showContractors } = ukConfig
  const showContractors = false

  const requestGetter = partial(get, requestData)

  const getInitialOwner = () => {
    const owner = requestGetter('owner_obj')
    const deleted = get(owner, 'deleted', false)
    const status = get(owner, 'status', 0)
    const group = get(owner, 'group')
    const flatsCount = get(owner, 'flats_count', 0)
    const name = getUserName(owner)
    const avatar = getUrlForAvatar(owner)
    const id = owner.id || owner.owner
    const availableToManager = get(owner, 'available_to_manager', true)

    return {
      deleted,
      name,
      avatar,
      group,
      id,
      flatsCount,
      status,
      availableToManager,
    }
  }

  const getInitialBuilding = () => {
    const id = requestGetter('building')
    const address = requestGetter(['address_obj', 'value'])

    return { id, address }
  }

  const getInitialFlat = () => {
    const id = requestGetter('flat')
    const number = requestGetter(['flat_obj', 'number'])

    if (!id) {
      return null
    }

    return { id, number }
  }

  const requestTypeId = requestGetter('request_type')
  const requestTypeName = requestGetter('request_type_name')
  const statusId = requestGetter(['status_obj', 'id'], null)

  const requestType =
    requestTypeId && requestTypeName
      ? { id: requestTypeId, name: requestTypeName }
      : null

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      statusId,
      dueDate: requestGetter('due_date') || '',
      requestType,
      owner: getInitialOwner(),
      building: getInitialBuilding(),
      flat: getInitialFlat(),
      switch_pipeline: undefined,
    },
    validationSchema: Yup.object({
      statusId: Yup.number(),
      dueDate: Yup.string().nullable(),
      requestType: Yup.object({
        id: Yup.number(),
        name: Yup.string(),
      }).nullable(),
      owner: Yup.object({
        id: Yup.number(),
        flatsCount: Yup.number(),
        name: Yup.string(),
        group: Yup.string(),
        avatar: Yup.string(),
        deleted: Yup.boolean(),
        status: Yup.number(),
        availableToManager: Yup.boolean(),
      }).nullable(),
      building: Yup.object()
        .nullable()
        .when('owner', (value, schema) =>
          value && isDwellerUser(value) ? schema.required() : schema
        ),
      flat: Yup.object()
        .nullable()
        .when('owner', (value, schema) =>
          value &&
          isDwellerUser(value) &&
          NOT_CONFIRMED_STATUS !== USER_STATUS[value.status]
            ? schema.required()
            : schema
        ),
      switch_pipeline: Yup.boolean().nullable(),
    }),
    onSubmit: submitValues => {
      const params = getInfoParams(formik.initialValues, submitValues, user)
      updateInfo(id, { ...params })
    },
  })

  const {
    permissions,
    label_objs: labels,
    created,
    id,
    assignee_objs: assignees,
    order_ids: orderIds,
    budget_plan,
    soft_archived,
    building,
    contractor_objs,
  } = requestData

  const [editing, onEdit] = useState(new Set())

  const canHaveLabels = permissions.can_view_labels && Boolean(labels)
  const canViewAssignees = permissions.can_view_assignee

  const handleEdit = names => () => {
    const newEditing = new Set(editing)

    names.forEach(name => {
      newEditing.add(name)
    })

    onEdit(newEditing)
  }

  const updateAssignees = payload => {
    const formattedAssignees = JSON.stringify(
      payload.assignees.map(({ id, fullname }) => id || fullname)
    )

    updateInfo(id, {
      assignees: formattedAssignees,
      status: IN_PROGRESS_REQUEST_STATUS,
      comment: payload.comment,
    })
  }

  const getFormikCb = fieldName => partial(formik.setFieldValue, fieldName)

  const handleSave = () => {
    formik.validateForm(formik.values)

    if (
      !outbound &&
      !isDweller &&
      formik.values.requestType?.id &&
      formik.values.requestType?.id !== requestTypeId
    ) {
      setConfirmModal(true)
    } else {
      handleConfirmSave()
    }
  }

  const handleConfirmSave = switch_pipeline => {
    formik.setFieldValue('switch_pipeline', switch_pipeline)

    if (
      formik.values.statusId !== statusId &&
      formik.values.statusId === COMPLETED_REQUEST_STATUS &&
      postsToOffline.length > 0
    ) {
      postsToOffline.forEach(uuid => editPost(uuid, { date_to: moment() }))
      setPostsToOffline([])
    }

    formik.submitForm().finally(() => {
      formik.setSubmitting(false)
      formik.resetForm()
      onEdit(new Set())
    })
  }

  const handleCancel = () => {
    formik.setSubmitting(false)
    formik.resetForm()

    onEdit(new Set())
  }

  const changeAddress = value => {
    const flat = get(value, 'flat')

    if (has(value, 'building')) {
      let params = null

      if (value.building) {
        const id = get(value, ['building', 'id'])
        const address =
          value.building.address_obj?.value || value.building.address

        params = { id, address }
      }

      formik.setFieldValue('building', params)
      formik.setFieldValue('flat', null)
    } else if (has(value, 'flat')) {
      let params = null

      if (value.flat) {
        const id = flat.id
        const number = flat.number

        params = { id, number }
      }

      formik.setFieldValue('flat', params)
    }
  }

  const handleOpenRelatedRequestsModal = () => {
    props.setModal(
      <RelatedRequestsModal
        requestId={id}
        onClose={() => props.setModal(null)}
      />
    )
  }

  const preparedDwellers =
    requestData.tenants_and_landlords?.filter(
      d => d.id !== requestData.owner_obj?.id
    ) || []

  return (
    <>
      <Modal isOpen={Boolean(activeModal)} onRequestClose={props.hideModal}>
        {activeModal}
      </Modal>
      {!outbound && !isDweller && relatedRequestsCount > 0 && (
        <Warning
          noArrow
          link={t('ViewList')}
          text={t('RelatedRequestsWarning', { count: relatedRequestsCount })}
          boxClassName={styles.warningBox}
          className={styles.warning}
          onClick={handleOpenRelatedRequestsModal}
        />
      )}
      <Block working={working}>
        <InfoHeader header outbound={outbound} />
        <form>
          <Main
            requestId={id}
            budget_plan={budget_plan}
            setPostsToOffline={setPostsToOffline}
            statusId={formik.values.statusId}
            outbound={outbound}
            permissions={permissions}
            created={created}
            onEdit={!outbound && handleEdit(['status'])}
            onChange={getFormikCb('statusId')}
          />
          {!outbound && !isDweller && (
            <RequestPipeline
              requestId={id}
              reload={formik.values.requestType?.id !== requestTypeId}
              setPipelineSwitched={props.setPipelineSwitched}
              softArchived={soft_archived}
            />
          )}
          {!outbound && (
            <DueDate
              editing={editing.has('dueDate')}
              value={formik.values.dueDate}
              user={user}
              permissions={permissions}
              onEdit={handleEdit(['dueDate'])}
              onChange={getFormikCb('dueDate')}
            />
          )}
          <RequestType
            value={formik.values.requestType}
            editing={editing.has('type')}
            permissions={permissions}
            onEdit={!outbound && handleEdit(['type'])}
            onChange={getFormikCb('requestType')}
          />
          <RequestOwnerName
            editing={editing.has('owner')}
            owner={formik.values.owner}
            building={formik.values.building}
            flat={formik.values.flat}
            outbound={outbound}
            permissions={permissions}
            onEdit={!outbound && handleEdit(['owner', 'building', 'flat'])}
            onChange={getFormikCb('owner')}
          />
          <OwnerAddress
            flatError={false}
            editing={editing.has('owner')}
            flat={formik.values.flat}
            building={formik.values.building}
            outbound={outbound}
            owner={formik.values.owner}
            permissions={permissions}
            onEdit={!outbound && handleEdit(['owner', 'building', 'flat'])}
            onChange={changeAddress}
          />
        </form>
        {preparedDwellers.length > 0 && (
          <Row title={t('TenantsAndLandlords')}>
            <RequestManagers
              role
              outbound={outbound}
              items={preparedDwellers}
              maxPreview={MAX_DWELLERS_PREVIEW}
            />
          </Row>
        )}
        {canViewAssignees && (
          <Assignees
            building={requestData.building}
            value={assignees}
            canEditAssignee={isStaffUser(user)}
            category={requestData.request_type}
            isSystem={requestData.is_system_type}
            outbound={outbound}
            softArchived={soft_archived}
            onEdit={!outbound && props.setModal}
            onChange={updateAssignees}
          />
        )}
        {!outbound && (isStaffUser(user) || showContractors) && (
          <RequestProviders
            softArchived={soft_archived}
            canEdit={isStaffUser(user)}
            contractors={contractor_objs || []}
            buildingId={building}
            onChange={value => updateInfo(id, value)}
          />
        )}
        {canHaveLabels && (
          <LabelsRow
            infoData={requestData}
            value={labels}
            permissions={permissions}
            onEdit={!outbound && props.setModal}
            onChange={updateLabels}
          />
        )}
        {isMarketplaceEnabled && (
          <Orders
            softArchived={soft_archived}
            permissions={permissions}
            orderIds={orderIds}
            onEdit={!outbound && props.setModal}
            onChange={value => updateInfo(id, value)}
          />
        )}
        {editing.size ? (
          <ButtonsBar
            dirty={formik.dirty}
            editing={editing}
            initialState={initialState}
            working={working}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        ) : null}
      </Block>
      {confirmModal && (
        <RequestCategoryConfirm
          isOpen
          onClose={closeModal}
          onConfirm={handleConfirmSave}
        />
      )}
    </>
  )
}

export default RequestViewInfo
